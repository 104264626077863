<template>
  <!--begin::Customer-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <div class="card-toolbar">
        <a v-b-modal.modal-vacation-add class="btn  btn-primary">
          Zaplanuj swój urlop
        </a>
        <VacationFormModal />
        <b-button v-if="this.vacationReservation.length > 0" v-b-toggle.collapse-mine variant="primary" class="ml-4">Pokaż moje urlopy</b-button>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-xxl-12 order-1 order-xxl-1">

          <template v-if="this.vacationReservation.length > 0">
            <b-collapse id="collapse-mine">
              <h4>Moje urlopy / wnioski urlopowe</h4>
              <div class="table-responsive">
                <v-data-table
                  dense
                  :headers="this.headersMine"
                  :items="this.vacationReservation"
                  class="table table-head-custom table-vertical-center table-head-bg table-borderless"
                  item-key="id"
                  mobile-breakpoint="0"
                  :footer-props="{
                    showFirstLastPage: true,
                    itemsPerPageText: 'Wyników na stronę',
                    pageText: '{0}-{1} na {2}',
                    itemsPerPageOptions: [10, 30, 50, 100]
                  }"
                  :items-per-page="10"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <template v-for="(item) in items">
                        <tr class="random" :key="item.id">
                          <td>
                            <a
                              class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                            >
                              {{ item.from_date.date.split(' ')[0] }}
                            </a>
                          </td>
                          <td>
                            <a
                              class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                            >
                              {{ item.to_date.date.split(' ')[0] }}
                            </a>
                          </td>
                          <td>
                            <a
                              class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                            >
                              {{ getStatus( item.reservation_status ) }}
                              <b v-if="item.reservation_status == 'rejected' && item.rejection_reason.length > 0">
                                <br>
                                Powód: {{ item.rejection_reason }}
                              </b>
                            </a>
                          </td>
                          <td>
                            <b-button 
                              variant="warning" 
                              class="btn-sm"
                              @click="cancelVacation(item)"
                            >Wycofaj wniosek / anuluj</b-button>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </template>
                </v-data-table>
              </div>
            </b-collapse>
          </template>

          <template 
            v-if="this.vacationReservationAllAwaiting.length > 0 && this.allowedUsers.includes(this.currentUserPersonalInfo.email)"
          >
            <h4>Wnioski oczekujące na akceptację / odmowę</h4>
            <div class="table-responsive">
              <v-data-table
                dense
                v-model="selected"
                :headers="this.headersPending"
                :items="this.vacationReservationAllAwaiting"
                class="table  table-head-custom table-vertical-center table-head-bg table-borderless"
                item-key="id"
                mobile-breakpoint="0"
                :footer-props="{
                  showFirstLastPage: true,
                  itemsPerPageText: 'Wyników na stronę',
                  pageText: '{0}-{1} na {2}',
                  itemsPerPageOptions: [10, 30, 50, 100]
                }"
                :items-per-page="10"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <template v-for="(item) in items">
                      <tr class="random" :key="item.id">
                        <td>
                          <a
                            class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                          >
                            {{ item.firstName + " " + item.lastName }}
                          </a>
                        </td>
                        <td>
                          <a
                            class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                          >
                            {{ item.from_date.date.split(' ')[0] }}
                          </a>
                        </td>
                        <td>
                          <a
                            class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                          >
                            {{ item.to_date.date.split(' ')[0] }}
                          </a>
                        </td>
                        <td>
                          <b-button 
                            variant="success" 
                            class="btn-sm mb-1 mt-1 mr-2"
                            @click="acceptVacation(item)"
                          >Zaakceptuj</b-button>
                          <b-button 
                            variant="warning" 
                            class="btn-sm"
                            @click="rejectVacation(item)"
                          >Odrzuć</b-button>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-data-table>
            </div>
          </template>

          <h4>Zaplanowane urlopy współpracowników</h4>
          <div class="table-responsive">
            <v-data-table
              dense
              v-model="selected"
              :headers="this.headers"
              :items="this.vacationReservationAllFiltered"
              class="table  table-head-custom table-vertical-center table-head-bg table-borderless"
              item-key="id"
              mobile-breakpoint="0"
              :footer-props="{
                showFirstLastPage: true,
                itemsPerPageText: 'Wyników na stronę',
                pageText: '{0}-{1} na {2}',
                itemsPerPageOptions: [10, 30, 50, 100]
              }"
              :items-per-page="10"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <template v-for="(item) in items">
                    <tr class="random" :key="item.id">
                      <td>
                        <a
                          class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                        >
                          {{ item.firstName + " " + item.lastName }}
                        </a>
                      </td>
                      <td>
                        <a
                          class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                        >
                          {{ item.from_date.date.split(' ')[0] }}
                        </a>
                      </td>
                      <td>
                        <a
                          class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                        >
                          {{ item.to_date.date.split(' ')[0] }}
                        </a>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import VacationFormModal from "@/view/content/modal/VacationFormModal.vue";
import Swal from "sweetalert2";

export default {
  name: "Vacation",
  components: { VacationFormModal },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Moja firma", route: "vacation" }
    ]);
    this.$store.dispatch("vacationReservationList");
    this.$store.dispatch("vacationReservationListAll");
  },
  // TODO: 1. Get data 2. Display data as table 3. Create popup form to add/edit records 4. Handle create/update request 5. Handle remove request
  computed: {
    ...mapGetters(["vacationReservation", "currentUserPersonalInfo", "vacationReservationAll"]),
    vacationReservationAllFiltered() {
      if (!this.vacationReservationAll)
        return [];

      return this.vacationReservationAll.filter(el => el.reservation_status == 'accepted')
    },
    vacationReservationAllAwaiting() {
      if (!this.vacationReservationAll)
        return [];

      return this.vacationReservationAll.filter(el => el.reservation_status == 'pending')
    }
  },
  data() {
    return {
      allowedUsers: [
        'tomasz.suchanek@ladrob.pl',
        'lukasz.latala@ladrob.pl'
      ],
      selected: [],
      headers: [
        {
          text: "Pracownik",
          value: "employee",
          style: "min-width: 80px",
          sortable: true
        },
        {
          text: "Urlop od dnia",
          value: "from_date",
          sortable: true
        },
        {
          text: "Urlop do dnia",
          value: "to_date",
          sortable: true
        },
      ],
      headersMine: [
        {
          text: "Urlop od dnia",
          value: "from_date",
          sortable: true
        },
        {
          text: "Urlop do dnia",
          value: "to_date",
          sortable: true
        },
        {
          text: "Status",
          value: "reservation_status",
          style: "min-width: 80px",
          sortable: true
        },
        {
          text: "Akcja",
          value: "action",
          sortable: true
        },
      ],
      headersPending: [
        {
          text: "Pracownik",
          value: "employee",
          style: "min-width: 80px",
          sortable: true
        },
        {
          text: "Urlop od dnia",
          value: "from_date",
          sortable: true
        },
        {
          text: "Urlop do dnia",
          value: "to_date",
          sortable: true
        },
        {
          text: "Akcja",
          value: "action",
          sortable: true
        },
      ]
    };
  },
  methods: {
    getStatus( status ) {
      switch( status ) {
        case "pending":
          return "Oczekujący";
        case "rejected":
          return "Odrzucony";
        case "accepted":
          return "Zaakceptowany";
        default:
          return "n/a";
      }
    },
    editItem( item ) {
      this.$bvModal.show("modal-vacation-add-" + item.id); 
    },
    acceptVacation(item) {
      Swal.fire({
        title: "Czy chcesz zatwierdzić urlop?",
        text: item.firstName + " " + item.lastName + " w dniach: " + item.from_date.date.split(' ')[0] + " do " + item.to_date.date.split(' ')[0],
        icon: "warning",
        confirmButtonClass: "btn btn-danger",
        cancelButtonClass: "btn btn-primary",
        showCancelButton: true,
        confirmButtonText: "Tak",
        cancelButtonText: "Nie"
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("vacationReservationUpdate", { 
            "reservationStatus": "accepted" ,
            "id": item.id,
            "fromDate": item.from_date.date,
            "toDate": item.to_date.date
          });
          Swal.fire("Gotowe!", "Wniosek odrzucony.", "success");

          this.$store.dispatch("vacationReservationList");
          this.$store.dispatch("vacationReservationListAll");
        }
      });
    },
    rejectVacation(item) {
      Swal.fire({
        title: "Czy chcesz odrzucić wniosek urlopowy?",
        text: item.firstName + " " + item.lastName + " w dniach: " + item.from_date.date.split(' ')[0] + " do " + item.to_date.date.split(' ')[0],
        icon: "warning",
        confirmButtonClass: "btn btn-danger",
        cancelButtonClass: "btn btn-primary",
        showCancelButton: true,
        confirmButtonText: "Tak",
        cancelButtonText: "Nie",
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
          placeholder: "Podaj powód odmowy"
        }
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("vacationReservationUpdate", { 
            "reservationStatus": "rejected" ,
            "id": item.id,
            "fromDate": item.from_date.date,
            "toDate": item.to_date.date,
            "rejectionReason": result.value
          });
          Swal.fire("Gotowe!", "Zaakceptowano urlop.", "success");

          this.$store.dispatch("vacationReservationList");
          this.$store.dispatch("vacationReservationListAll");
        }
      });
    },
    cancelVacation(item) {
      Swal.fire({
        title: "Czy chcesz wycofać wniosek urlopowy?",
        text: item.firstName + " " + item.lastName + " w dniach: " + item.from_date.date.split(' ')[0] + " do " + item.to_date.date.split(' ')[0],
        icon: "warning",
        confirmButtonClass: "btn btn-danger",
        cancelButtonClass: "btn btn-primary",
        showCancelButton: true,
        confirmButtonText: "Tak",
        cancelButtonText: "Nie",
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("vacationReservationDelete", { 
            "id": item.id            
          });
          Swal.fire("Gotowe!", "Wycofano urlop.", "success");

          this.$store.dispatch("vacationReservationList");
          this.$store.dispatch("vacationReservationListAll");
        }
      });
    }
  }
};
</script>

