<template>
  <div>
    <!-- The modal -->
    <b-modal
      :id="modalId"
      ref="modal"
      title="Złóż wniosek urlopowy"
      ok-title="Złóż wniosek"
      cancel-title="Anuluj"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      @show="fillModal"
      @cancel="resetForm"
      @ok="handleModalOk"
    >
      <b-form novalidate>
        <b-form-group
          label="Urlop od dnia"
          label-for="days_total"
          invalid-feedback="Pole wymagane"
          :state="validateField('fromDate')"
        >
          <b-form-datepicker
            id="fromDate"
            v-model="form.fromDate"
          />
        </b-form-group>
        <b-form-group
          label="Urlop do dnia"
          label_for="toDate"
          invalid-feedback="Pole wymagane"
          :state="validateField('toDate')"
        >
          <b-form-datepicker
            id="toDate"
            v-model="form.toDate"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "VacationFormModal",
  data() {
    return {
      form: {
        fromDate: "",
        toDate: "",
      }
    };
  },
  validations: {
    form: {
      employee: {
        required
      },
      days_total: {
        required
      },
      days_used: {
        required
      }
    }
  },
  methods: {
    validateField(field) {
      const { $dirty, $error } = this.form[field];
      return $dirty ? !$error : null;
    },
    validateForm() {
      this.$v.form.$touch();
      this.form.formState = !this.form.$invalid;
    },
    resetForm() {
      this.form = {
        fromDate: "",
        toDate: "",
      };
    },
    handleModalOk(event) {
      event.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      this.validateForm();
      if (this.form.formState) {
        this.$store.dispatch(
          "vacationReservationAdd",
          { ...this.form }
        );

        this.$nextTick(() => {
          this.$bvModal.hide(this.modalId);
        });
      }
    },
    fillModal() {
      if (this.vacation) {
        this.form = {
          fromDate: "",
          toDate: "",
        };
      } else {
        this.resetForm();
      }
    }
  },
  props: ["vacation"],
  computed: {
    modalId() {
      return (
        "modal-vacation-add" + (this.vacation ? "-" + this.vacation.id : "")
      );
    }
  }
}
</script>

<style scoped>

</style>
