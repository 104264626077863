var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card card-custom card-stretch gutter-b"},[_c('div',{staticClass:"card-header border-0 pt-5"},[_c('div',{staticClass:"card-toolbar"},[_c('a',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-vacation-add",modifiers:{"modal-vacation-add":true}}],staticClass:"btn  btn-primary"},[_vm._v(" Zaplanuj swój urlop ")]),_c('VacationFormModal'),(this.vacationReservation.length > 0)?_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-mine",modifiers:{"collapse-mine":true}}],staticClass:"ml-4",attrs:{"variant":"primary"}},[_vm._v("Pokaż moje urlopy")]):_vm._e()],1)]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xxl-12 order-1 order-xxl-1"},[(this.vacationReservation.length > 0)?[_c('b-collapse',{attrs:{"id":"collapse-mine"}},[_c('h4',[_vm._v("Moje urlopy / wnioski urlopowe")]),_c('div',{staticClass:"table-responsive"},[_c('v-data-table',{staticClass:"table table-head-custom table-vertical-center table-head-bg table-borderless",attrs:{"dense":"","headers":this.headersMine,"items":this.vacationReservation,"item-key":"id","mobile-breakpoint":"0","footer-props":{
                  showFirstLastPage: true,
                  itemsPerPageText: 'Wyników na stronę',
                  pageText: '{0}-{1} na {2}',
                  itemsPerPageOptions: [10, 30, 50, 100]
                },"items-per-page":10},scopedSlots:_vm._u([{key:"body",fn:function(ref){
                var items = ref.items;
return [_c('tbody',[_vm._l((items),function(item){return [_c('tr',{key:item.id,staticClass:"random"},[_c('td',[_c('a',{staticClass:"text-dark-75 text-hover-primary mb-1 font-size-xs"},[_vm._v(" "+_vm._s(item.from_date.date.split(' ')[0])+" ")])]),_c('td',[_c('a',{staticClass:"text-dark-75 text-hover-primary mb-1 font-size-xs"},[_vm._v(" "+_vm._s(item.to_date.date.split(' ')[0])+" ")])]),_c('td',[_c('a',{staticClass:"text-dark-75 text-hover-primary mb-1 font-size-xs"},[_vm._v(" "+_vm._s(_vm.getStatus( item.reservation_status ))+" "),(item.reservation_status == 'rejected' && item.rejection_reason.length > 0)?_c('b',[_c('br'),_vm._v(" Powód: "+_vm._s(item.rejection_reason)+" ")]):_vm._e()])]),_c('td',[_c('b-button',{staticClass:"btn-sm",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.cancelVacation(item)}}},[_vm._v("Wycofaj wniosek / anuluj")])],1)])]})],2)]}}],null,false,4070024209)})],1)])]:_vm._e(),(this.vacationReservationAllAwaiting.length > 0 && this.allowedUsers.includes(this.currentUserPersonalInfo.email))?[_c('h4',[_vm._v("Wnioski oczekujące na akceptację / odmowę")]),_c('div',{staticClass:"table-responsive"},[_c('v-data-table',{staticClass:"table  table-head-custom table-vertical-center table-head-bg table-borderless",attrs:{"dense":"","headers":this.headersPending,"items":this.vacationReservationAllAwaiting,"item-key":"id","mobile-breakpoint":"0","footer-props":{
                showFirstLastPage: true,
                itemsPerPageText: 'Wyników na stronę',
                pageText: '{0}-{1} na {2}',
                itemsPerPageOptions: [10, 30, 50, 100]
              },"items-per-page":10},scopedSlots:_vm._u([{key:"body",fn:function(ref){
              var items = ref.items;
return [_c('tbody',[_vm._l((items),function(item){return [_c('tr',{key:item.id,staticClass:"random"},[_c('td',[_c('a',{staticClass:"text-dark-75 text-hover-primary mb-1 font-size-xs"},[_vm._v(" "+_vm._s(item.firstName + " " + item.lastName)+" ")])]),_c('td',[_c('a',{staticClass:"text-dark-75 text-hover-primary mb-1 font-size-xs"},[_vm._v(" "+_vm._s(item.from_date.date.split(' ')[0])+" ")])]),_c('td',[_c('a',{staticClass:"text-dark-75 text-hover-primary mb-1 font-size-xs"},[_vm._v(" "+_vm._s(item.to_date.date.split(' ')[0])+" ")])]),_c('td',[_c('b-button',{staticClass:"btn-sm mb-1 mt-1 mr-2",attrs:{"variant":"success"},on:{"click":function($event){return _vm.acceptVacation(item)}}},[_vm._v("Zaakceptuj")]),_c('b-button',{staticClass:"btn-sm",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.rejectVacation(item)}}},[_vm._v("Odrzuć")])],1)])]})],2)]}}],null,false,2173368088),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)]:_vm._e(),_c('h4',[_vm._v("Zaplanowane urlopy współpracowników")]),_c('div',{staticClass:"table-responsive"},[_c('v-data-table',{staticClass:"table  table-head-custom table-vertical-center table-head-bg table-borderless",attrs:{"dense":"","headers":this.headers,"items":this.vacationReservationAllFiltered,"item-key":"id","mobile-breakpoint":"0","footer-props":{
              showFirstLastPage: true,
              itemsPerPageText: 'Wyników na stronę',
              pageText: '{0}-{1} na {2}',
              itemsPerPageOptions: [10, 30, 50, 100]
            },"items-per-page":10},scopedSlots:_vm._u([{key:"body",fn:function(ref){
            var items = ref.items;
return [_c('tbody',[_vm._l((items),function(item){return [_c('tr',{key:item.id,staticClass:"random"},[_c('td',[_c('a',{staticClass:"text-dark-75 text-hover-primary mb-1 font-size-xs"},[_vm._v(" "+_vm._s(item.firstName + " " + item.lastName)+" ")])]),_c('td',[_c('a',{staticClass:"text-dark-75 text-hover-primary mb-1 font-size-xs"},[_vm._v(" "+_vm._s(item.from_date.date.split(' ')[0])+" ")])]),_c('td',[_c('a',{staticClass:"text-dark-75 text-hover-primary mb-1 font-size-xs"},[_vm._v(" "+_vm._s(item.to_date.date.split(' ')[0])+" ")])])])]})],2)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }